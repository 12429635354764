define("ember-cookies/utils/serialize-cookie", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeCookie = void 0;
  const serializeCookie = function (name, value) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let cookie = "".concat(name, "=").concat(value);
    if (!(0, _utils.isEmpty)(options.domain)) {
      cookie = "".concat(cookie, "; domain=").concat(options.domain);
    }
    if ((0, _utils.typeOf)(options.expires) === 'date') {
      cookie = "".concat(cookie, "; expires=").concat(options.expires.toUTCString());
    }
    if (!(0, _utils.isEmpty)(options.maxAge)) {
      cookie = "".concat(cookie, "; max-age=").concat(options.maxAge);
    }
    if (options.secure) {
      cookie = "".concat(cookie, "; secure");
    }
    if (options.httpOnly) {
      cookie = "".concat(cookie, "; httpOnly");
    }
    if (!(0, _utils.isEmpty)(options.path)) {
      cookie = "".concat(cookie, "; path=").concat(options.path);
    }
    if (!(0, _utils.isEmpty)(options.sameSite)) {
      cookie = "".concat(cookie, "; SameSite=").concat(options.sameSite);
    }
    return cookie;
  };
  _exports.serializeCookie = serializeCookie;
});